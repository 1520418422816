<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="py-1 px-5">
      <v-row>
        <v-col md="1" class=".col">
          <div class="font-weight-medium">Изображение</div>
          <div v-if="service.picture" class="ServiceListItemImgContainer">
            <img :src="url() + service.picture.path" alt="Изображение" />
          </div>
        </v-col>
        <v-col md="5" class=".col">
          <div style="display: flex">
            <div class="font-weight-medium">Название:</div>
            <div>{{ service.name }}</div>
          </div>
          <div style="display: flex">
            <div class="font-weight-medium">Артикул:</div>
            <div>{{ service.article }}</div>
          </div>
          <div style="display: flex">
            <div class="font-weight-medium">Тип услуги:</div>
            <div>{{ getServiceTypeGroupName(service.type) }}</div>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <span class="font-weight-medium">Слайды</span>
      <div v-if="service.slides" style="display: flex" class="ServiceListItemSlidesContainer">
        <div v-for="(slide, index) in service.slides" :key="index" class="ServiceListItemSlidesImage">
          <img :src="url() + slide.path" alt="Изображение" />
        </div>
      </div>
      <div style="display: flex">
        <div class="font-weight-medium">Наименование в чеке:</div>
        <div>{{ service.chequeName }}</div>
      </div>
      <div style="display: flex">
        <div>Название</div>
        <div v-for="(lang, key) in service.title" :key="key" class="ServiceListItemLangBlock">
          {{ key }}
        </div>
        {{ this.getLangText(service.title) }}
      </div>
      <div style="display: flex">
        <div>Заголовок</div>
        <div v-for="(lang, key) in service.header" :key="key" class="ServiceListItemLangBlock">
          {{ key }}
        </div>
        {{ this.getLangText(service.header) }}
      </div>
      <div style="display: flex">
        <div>Описание</div>
        <div v-for="(lang, key) in service.description" :key="key" class="ServiceListItemLangBlock">
          {{ key }}
        </div>
        {{ this.getLangText(service.description) }}
      </div>

      <div>
        Тарифы
        <v-row>
          <v-col cols="2" class=".col">Артикул</v-col>
          <v-col cols="4" class=".col">Название</v-col>
          <v-col cols="2" class=".col">Тип</v-col>
          <v-col cols="2" class=".col">Налоговая группа</v-col>
          <v-col cols="2" class=".col">Цена</v-col>
        </v-row>
        <div v-for="tariff in service.tariffs" :key="tariff.id" style="color: red">
          <v-row>
            <v-col cols="2" class=".col">{{ tariff.article }}</v-col>
            <v-col cols="4" class=".col">{{ tariff.chequeName }}</v-col>
            <v-col cols="2" class=".col">{{ tariff.type }}</v-col>
            <v-col cols="2" class=".col">{{ tariff.tax }}</v-col>
            <v-col cols="2" class=".col">{{ tariff.price }}</v-col>
          </v-row>
          <div style="display: flex">
            <div>Название</div>
            <div v-for="(lang, key) in tariff.title" :key="tariff.id + 'tariffTitle' + key" class="ServiceListItemLangBlock">
              {{ key }}
            </div>
            <div v-if="tariff.title">
              <!--              {{ this.getLangText(tariff.title) }}-->
            </div>
          </div>
          <div style="display: flex">
            <div>Заголовок</div>
            <div v-for="(lang, key) in tariff.header" :key="tariff.id + 'tariffHeader' + key" class="ServiceListItemLangBlock">
              {{ key }}
            </div>
            <div v-if="tariff.header">
              <!--              {{ this.getLangText(tariff.header) }}-->
            </div>
          </div>
          <div style="display: flex">
            <div>Описание</div>
            <div v-for="(lang, key) in tariff.description" :key="tariff.id + 'tariffDescription' + key" class="ServiceListItemLangBlock">
              {{ key }}
            </div>
            <div v-if="tariff.description">
              <!--              {{ this.getLangText(tariff.description) }}-->
            </div>
          </div>
        </div>
      </div>
      <DialogEdit :service="service" :user="user" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment'
import DialogEdit from './ServiceDialogEdit'
import ServiceWorker from '@/helpers/service/ServiceWorker'
import config from '@/config/config'

export default {
  props: {
    service: Object,
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    DialogEdit
  },
  filters: {
    format(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fromNow(date) {
      return moment(date).fromNow()
    }
  },
  methods: {
    getLangText(langObject) {
      let returnString = ''
      if (!langObject) {
        return returnString
      }
      if (langObject.ru) {
        returnString = langObject.ru
      } else if (langObject.en) {
        returnString = langObject.en
      } else {
        if (langObject.length > 0) {
          returnString = langObject[0]
        }
      }
      return returnString
    },
    url() {
      return config.pictureBaseUrl
    },
    taxName(tax) {
      return ServiceWorker.getTaxGroupName(tax)
    },
    getServiceTypeGroupName: ServiceWorker.getServiceTypeGroupName
  }
}
</script>
<style>
.ServiceListItemImgContainer {
  align-content: start;
  width: 90%;
  height: 100px;
}

.ServiceListItemSlidesImage {
  margin: 0 5px 0 0;
  width: 100px;
  height: 100px;
  border: solid 1px;
  border-radius: 2px;
}

.ServiceListItemSlidesContainer {
  padding-bottom: 5px;
  display: flex;
  align-content: flex-start;
}

.ServiceListItemLangBlock {
  padding: 0 5px 0 5px;
  border-style: inset;
  border-radius: 5px;
  min-width: 50px;
  text-align: center;
}
</style>
