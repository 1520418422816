<template>
  <v-dialog v-model="dialog" max-width="1200" persistent>
    <template v-slot:activator="{ on }">
      <v-btn text small color="grey" v-on="on" class="ma-2" @click="createServiceObject">
        <v-icon small left>mdi-pencil</v-icon>
        Редактировать
      </v-btn>
    </template>
    <v-card class="product-edit-card px-4" outlined>
      <v-form ref="productForm" @submit.prevent="checkInput">
        <v-card-title>
          <span>Изменить услугу</span>
        </v-card-title>
        <div v-if="serviceObject">
          <v-card-text>
            <span class="font-weight-medium">Изображение</span>
            <div v-if="serviceObject.picture" class="ServiceDialogEditImgContainer">
              <img :src="url() + serviceObject.picture.path" alt="Изображение" />
            </div>
            <span class="font-weight-medium">Слайды</span>
            <div v-if="serviceObject.slides" style="display: flex" class="ServiceDialogEditSlidesContainer">
              <div v-for="(slide, index) in serviceObject.slides" :key="index" class="ServiceDialogEditSlidesImage">
                <img :src="url() + slide.path" alt="Изображение" />
              </div>
            </div>
            <span class="font-weight-medium">Артикул</span>
            <v-text-field v-model="serviceObject.article" hide-details="auto" class="mt-1 mb-3" single-line outlined dense type="number"></v-text-field>
            <span class="font-weight-medium">Название</span>
            <v-text-field v-model="serviceObject.name" class="mt-1" single-line outlined dense />
            <span class="font-weight-medium">Название в чеке</span>
            <v-text-field v-model="serviceObject.chequeName" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            <span class="font-weight-medium">Тип услуги</span>
            <v-select
              v-model="serviceObject.type"
              :rules="taxRules"
              :items="typeList()"
              item-value="value"
              item-text="name"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-select>

            <span class="font-weight-medium">Онлайн код</span>
            <v-text-field v-model="serviceObject.onlineCode" hide-details="auto" class="mt-1 mb-3" single-line outlined dense type="number"></v-text-field>

            <span class="font-weight-medium">Продолжительность</span>
            <v-text-field v-model="serviceObject.duration" hide-details="auto" class="mt-1 mb-3" single-line outlined dense type="number"></v-text-field>

            <v-col class="font-weight-medium">Наименование</v-col>
            <div v-for="(value, key) in serviceObject.title" :key="`serviceTitle${key}`" class="ServiceDialogEditLangBlock">
              <div class="ServiceDialogEditDeleteButton">
                <v-btn @click="deleteLocalization(serviceObject.title, key)">-</v-btn>
              </div>
              <div class="ServiceDialogEditLangElement">
                <v-select v-model="value.lang" :items="langList()" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-select>
              </div>

              <v-text-field v-model="value.title" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            </div>
            <div>
              <v-btn @click="addLocalization(serviceObject, 'title')"> + Добавить язык</v-btn>
            </div>

            <div class="font-weight-medium">Заголовок</div>
            <div v-for="(value, key) in serviceObject.header" :key="`serviceHeader${key}`" class="ServiceDialogEditLangBlock">
              <div class="ServiceDialogEditDeleteButton">
                <v-btn @click="deleteLocalization(serviceObject.header, key)">-</v-btn>
              </div>
              <div class="ServiceDialogEditLangElement">
                <v-select v-model="value.lang" :items="langList()" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-select>
              </div>

              <v-text-field v-model="value.title" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            </div>
            <div>
              <v-btn @click="addLocalization(serviceObject, 'header')"> + Добавить язык</v-btn>
            </div>

            <v-col class="font-weight-medium">Описание</v-col>
            <div v-for="(value, key) in serviceObject.description" :key="`serviceDescription${key}`" class="ServiceDialogEditLangBlock">
              <div class="ServiceDialogEditDeleteButton">
                <v-btn @click="deleteLocalization(serviceObject.description, key)">-</v-btn>
              </div>
              <div class="ServiceDialogEditLangElement">
                <v-select v-model="value.lang" :items="langList()" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-select>
              </div>

              <v-text-field v-model="value.title" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            </div>
            <div>
              <v-btn @click="addLocalization(serviceObject, 'description')"> + Добавить язык</v-btn>
            </div>

            <div class="font-weight-medium">Список тарифов</div>
            <v-divider></v-divider>
            <div v-for="(tariff, key) in serviceObject.tariffs" :key="`serviceTariff${key}`" class="ServiceDialogEditLangBlock">
              <v-col class="ServiceDialogEditDeleteButton col-1">
                <v-btn @click="deleteTariff(key)">-</v-btn>
              </v-col>
              <v-col>
                <span class="font-weight-medium">Артикул</span>
                <v-text-field v-model="tariff.article" hide-details="auto" class="mt-1 mb-3" single-line outlined dense type="number"></v-text-field>
                <span class="font-weight-medium">Название в чеке</span>
                <v-text-field v-model="tariff.chequeName" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
                <span class="font-weight-medium">Тип тарифа</span>
                <v-select
                  v-model="tariff.type"
                  :items="tariffTypeList()"
                  item-value="value"
                  item-text="name"
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  type="number"
                ></v-select>
                <span class="font-weight-medium">Налоговая группа</span>
                <v-select
                  v-model="tariff.tax"
                  :items="taxList()"
                  item-value="value"
                  item-text="name"
                  hide-details="auto"
                  class="mt-1 mb-3"
                  single-line
                  outlined
                  dense
                  type="number"
                ></v-select>
                <span class="font-weight-medium">Цена</span>
                <v-text-field v-model="tariff.price" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>

                <v-col class="font-weight-medium">Наименование</v-col>
                <div v-for="(value, key) in tariff.title" :key="`serviceTitle${key}`" class="ServiceDialogEditLangBlock">
                  <div class="ServiceDialogEditDeleteButton">
                    <v-btn @click="deleteLocalization(tariff.title, key)">-</v-btn>
                  </div>
                  <div class="ServiceDialogEditLangElement">
                    <v-select v-model="value.lang" :items="langList()" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-select>
                  </div>

                  <v-text-field v-model="value.title" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
                </div>
                <div>
                  <v-btn @click="addLocalization(tariff, 'title')"> + Добавить язык</v-btn>
                </div>

                <div class="font-weight-medium">Заголовок</div>
                <div v-for="(value, key) in tariff.header" :key="`serviceHeader${key}`" class="ServiceDialogEditLangBlock">
                  <div class="ServiceDialogEditDeleteButton">
                    <v-btn @click="deleteLocalization(tariff.header, key)">-</v-btn>
                  </div>
                  <div class="ServiceDialogEditLangElement">
                    <v-select v-model="value.lang" :items="langList()" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-select>
                  </div>

                  <v-text-field v-model="value.title" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
                </div>
                <div>
                  <v-btn @click="addLocalization(tariff, 'header')"> + Добавить язык</v-btn>
                </div>

                <v-col class="font-weight-medium">Описание</v-col>
                <div v-for="(value, key) in tariff.description" :key="`serviceDescription${key}`" class="ServiceDialogEditLangBlock">
                  <div class="ServiceDialogEditDeleteButton">
                    <v-btn @click="deleteLocalization(tariff.description, key)">-</v-btn>
                  </div>
                  <div class="ServiceDialogEditLangElement">
                    <v-select v-model="value.lang" :items="langList()" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-select>
                  </div>

                  <v-text-field v-model="value.title" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
                </div>
                <div>
                  <v-btn @click="addLocalization(tariff, 'description')"> + Добавить язык</v-btn>
                </div>
              </v-col>
            </div>
            <div>
              <v-btn @click="addTariff()"> + Добавить тариф</v-btn>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn :loading="loading" type="submit" depressed color="primary" class="text-capitalize">Сохранить</v-btn>
          <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
          <ServiceDialogUpload />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ServiceDialogUpload from './ServiceDialogUpload'
import config from '@/config/config'
import ServiceWorker from '@/helpers/service/ServiceWorker'

export default {
  props: {
    service: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isDeletePrice: this.user && this.user.id === 1,
      disabled: true,
      dialog: false,
      loading: false,
      serviceObject: null,
      needRules: [(v) => !!v || 'Обязательное поле'],
      newLangId: 0,
      newPriceId: 0,
      file: {},
      notEmptyRules: [(v) => !!v || 0 || 'Обязательное поле'],
      taxRules: [(v) => v >= 0 || 'Обязательное поле'],
      datePicker: []
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({ productPicture: 'service/productPicture' })
  },
  methods: {
    createServiceObject() {
      const service = JSON.parse(JSON.stringify(this.service))

      const title = []
      if (service.title) {
        Object.entries(service.title).forEach((entry) => {
          const [key, value] = entry
          title.push({ lang: key, title: value })
        })
      }
      service.title = title
      const header = []
      if (service.header) {
        Object.entries(service.header).forEach((entry) => {
          const [key, value] = entry
          header.push({ lang: key, title: value })
        })
      }
      service.header = header

      const description = []
      if (service.description) {
        Object.entries(service.description).forEach((entry) => {
          const [key, value] = entry
          description.push({ lang: key, title: value })
        })
      }
      service.description = description

      if (service.tariffs) {
        Object.values(service.tariffs).forEach((tariff) => {
          if (tariff.title) {
            const title = []
            Object.entries(tariff.title).forEach((entry) => {
              const [key, value] = entry
              title.push({ lang: key, title: value })
            })
            tariff.title = title
          }
          if (tariff.header) {
            const title = []
            Object.entries(tariff.header).forEach((entry) => {
              const [key, value] = entry
              title.push({ lang: key, title: value })
            })
            tariff.header = title
          }
          if (tariff.description) {
            const title = []
            Object.entries(tariff.description).forEach((entry) => {
              const [key, value] = entry
              title.push({ lang: key, title: value })
            })
            tariff.description = title
          }
        })
      }
      this.serviceObject = service
    },
    getServiceObjectForSave() {
      const service = JSON.parse(JSON.stringify(this.serviceObject))
      if (service.title) {
        const newLangObject = {}
        Object.values(service.title).forEach((lang) => {
          newLangObject[lang.lang] = lang.title
        })
        service.title = newLangObject
      }
      if (service.header) {
        const newLangObject = {}
        Object.values(service.header).forEach((lang) => {
          newLangObject[lang.lang] = lang.title
        })
        service.header = newLangObject
      }
      if (service.description) {
        const newLangObject = {}
        Object.values(service.description).forEach((lang) => {
          newLangObject[lang.lang] = lang.title
        })
        service.description = newLangObject
      }
      if (service.tariffs) {
        Object.values(service.tariffs).forEach((tariff) => {
          if (tariff.title) {
            const newLangObject = {}
            Object.values(tariff.title).forEach((lang) => {
              newLangObject[lang.lang] = lang.title
            })
            tariff.title = newLangObject
          }
          if (tariff.header) {
            const newLangObject = {}
            Object.values(tariff.header).forEach((lang) => {
              newLangObject[lang.lang] = lang.title
            })
            tariff.header = newLangObject
          }
          if (tariff.description) {
            const newLangObject = {}
            Object.values(tariff.description).forEach((lang) => {
              newLangObject[lang.lang] = lang.title
            })
            tariff.description = newLangObject
          }
        })
      }
      return service
    },
    deleteLocalization(object, key) {
      this.$delete(object, key)
    },
    addLocalization(object, key) {
      if (!object[key]) {
        this.$set(object, key, [])
      }
      this.$set(object[key], object[key].length, {
        lang: ``,
        title: ``
      })
    },
    deleteTariff(key) {
      this.$delete(this.serviceObject.tariffs, key)
    },
    addTariff() {
      this.$set(this.serviceObject.tariffs, this.serviceObject.tariffs.length, {
        price: 100.0
      })
    },
    checkInput() {
      if (this.$refs.productForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      const serviceObjectForSave = this.getServiceObjectForSave()
      this.updateService({ service: serviceObjectForSave, projectId: this.projectId })
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },

    closeDialog() {
      this.dialog = false
      this.serviceObject = null
    },
    url() {
      return config.pictureBaseUrl
    },
    taxList() {
      return ServiceWorker.getServiceTaxList()
    },
    langList() {
      return ServiceWorker.getServiceLangList()
    },
    typeList() {
      return ServiceWorker.getServiceTypeList()
    },
    tariffTypeList() {
      return ServiceWorker.getServiceTariffTypeList()
    },
    ...mapActions({ updateService: 'service/updateService' })
  },
  mounted() {
    this.createServiceObject()
  },
  components: {
    ServiceDialogUpload
  }
}
</script>
<style scoped>
.product-edit-card .v-btn {
  font-weight: 400;
}

.ServiceDialogEditImgContainer {
  align-content: start;
  width: 100px;
  height: 100px;
}

.ServiceDialogEditSlidesImage {
  margin: 0 5px 0 0;
  width: 100px;
  height: 100px;
  border: solid 1px;
  border-radius: 2px;
}

.ServiceDialogEditSlidesContainer {
  padding-bottom: 5px;
  display: flex;
  align-content: flex-start;
}

.ServiceDialogEditLangBlock {
  display: flex;
  padding: 0 5px 0 5px;
}

.ServiceDialogEditLangElement {
  font-size: large;
  vertical-align: text-bottom;
  text-align: center;
}

.ServiceDialogEditDeleteButton {
  padding: 5px 5px 5px 5px;
  text-align: center;
}
</style>
