const TAX = [
  {
    value: 0,
    name: 'НДС 0%'
  },
  {
    value: 1,
    name: 'НДС 10%'
  },
  {
    value: 2,
    name: 'НДС 20%'
  },
  {
    value: 3,
    name: 'НДС 10/110%'
  },
  {
    value: 4,
    name: 'НДС 20/120%'
  },
  {
    value: 5,
    name: 'БезНДС'
  }
]
const LOCALIZATIONS = ['ru', 'en', 'de', 'fi', 'it']
const TYPES = [
  {
    value: 1,
    name: 'Экскурсия'
  },
  {
    value: 2,
    name: 'Билеты в парк'
  },
  {
    value: 3,
    name: 'Конная прогулка'
  },
  {
    value: 4,
    name: 'Фотокупе'
  },
  {
    value: 5,
    name: 'Фотосессия'
  }
]
const TARIFF_TYPES = [
  {
    value: 1,
    name: 'Полный'
  },
  {
    value: 2,
    name: 'Льготный'
  },
  {
    value: 3,
    name: 'Особый'
  }
]

export default {
  getServiceTypeGroupName(group) {
    for (let i = 0; i < TYPES.length; i++) {
      if (TYPES[i].value === group) {
        return TYPES[i].name
      }
    }
    return 'Код: ' + group
  },
  getServiceTaxGroupName(group) {
    for (let i = 0; i < TAX.length; i++) {
      if (TAX[i].value === group) {
        return TAX[i].name
      }
    }
    return 'Код: ' + group
  },
  getServiceTypeList() {
    return TYPES
  },
  getServiceTariffTypeList() {
    return TARIFF_TYPES
  },
  getServiceTaxList() {
    return TAX
  },
  getServiceLangList() {
    return LOCALIZATIONS
  }
}
