<template>
  <div class="kit-tasks">
    <h1 class="subtitle-1 grey--text">Услуги</h1>
    <DialogCreate :user="user" />
    <List :services="services" :user="user" />
  </div>
</template>

<script>
import List from '@/components/service/ServiceList'
import DialogCreate from '@/components/service/ServiceDialogCreate'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    List,
    DialogCreate
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({
      services: 'service/services',
      user: 'auth/user'
    })
  },
  mounted() {
    this.fetch({
      projectId: this.projectId
    })
  },
  methods: {
    ...mapActions('service', ['fetch'])
  },
  watch: {
    file(updatedFile) {
      if (!this.title) {
        this.title = updatedFile.name
      }
    }
  }
}
</script>
