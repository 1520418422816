<template>
  <v-expansion-panels hover>
    <ListItem v-for="service in services" :key="service.id" :service="service" :user="user" />
  </v-expansion-panels>
</template>

<script>
import ListItem from './ServiceListItem'

export default {
  props: {
    services: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    ListItem
  }
}
</script>
